import React, { Component } from 'react';
export default class About extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="about">
         <div className="row">

            <div className="three columns">

               <img className="profile-pic"  src="images/profilepic.jpg" alt="" />

            </div>

            <div className="nine columns main-col">

               <h2>About Us</h2>
               <p>
               Welcome to Ashoka Enterprises, your ultimate destination for a world of convenience and cutting-edge technology. Explore a curated collection of home appliances and electronic products that redefine modern living. <br/>From kitchen essentials that transform cooking into an art, to smart devices that make your home truly intelligent, we have it all.

Step into a realm of innovation where every corner of your home comes alive. Our diverse range of kitchen appliances brings culinary dreams to life. Experience the joy of precision cooking with advanced stovetops and ovens. Elevate your mornings with state-of-the-art coffee makers and toasters that perfectly complement your daily routine.

<br/>Laundry is no longer a chore with our energy-efficient washing machines and dryers. Embrace smart technology that adapts to your needs, saving you time and resources. Discover a range of eco-friendly home appliances that promote sustainability without compromising performance.

Transform your home into a haven of entertainment with our collection of electronic products. <br/>Immerse yourself in stunning visuals with our latest TV models, and experience breathtaking sound quality with top-notch audio systems. Stay connected and organized with cutting-edge smartphones and laptops that blend productivity and style seamlessly.

<br/>Our knowledgeable staff is committed to guiding you through our vast selection, helping you find the perfect match for your lifestyle. We believe in creating not just a store, but a personalized experience that caters to your unique needs. Embrace the future of living as you explore our shop's offerings that combine innovation, functionality, and aesthetic appeal.

<br/>At our store, we understand that home is where the heart is, and our products are designed to enhance your space in every way. With a focus on quality, performance, and the latest technology trends, we ensure that your investment brings you both immediate satisfaction and long-term value.<br/> Visit us today to witness the seamless integration of technology and comfort, and take the first step towards a smarter, more efficient, and more delightful living environment
               </p>

               <div className="row">

                  <div className="columns contact-details">

                  <h2>Contact Details</h2>
                  <p className="address">
       						<span>Ashoka Enterprises</span>
                     <br></br>
       						   <span>
                            THE BIGGEST ELECTRONICS SHOW ROOM IN CHANDIGARH Shop no 4-5 Naya gaon, Chandigarh, India
                    </span>
                    <br></br>
                    <span>+919915043714</span>
       					   </p>
                  </div>
               </div>
            </div>
         </div>
      </section>
    );
  }
}