let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Rahul Bhatia",
    "role": "Frontend Developer and Data Scientist",
    "linkedinId":"Your LinkedIn Id",
    "skypeid": "Your skypeid",
    "roleDescription": "I like dabbling in various parts of frontend development and like to learn about new technologies, write technical articles or simply play games in my free time.",
    "socialLinks":[
        {
          "name":"facebook",
          "url":"https://www.facebook.com/ashokaenterpriseschd",
          "className":"fa fa-facebook"
        },
        // {
        //   "name":"github",
        //   "url":"http://github.com/rbhatia46",
        //   "className":"fa fa-github"
        // },
        // {
        //   "name":"skype",
        //   "url":"http://twitter.com/rbhatia46",
        //   "className":"fa fa-twitter"
        // }
      ],
    "aboutme":"I am currently a pre-final year student at The LNM Institute of Information Technology and pursuing my B.Tech from here. I am a self taught Full Stack Web Developer, currently diving deeper into Machine Learning. I believe that to be successful in life, one needs to be obsessive with their dreams and keep working towards them.",
    "address":"India",
    "website":"https://rbhatia46.github.io",
    "education":[
      {
        "UniversityName":"We Value our Customers",
        "specialization":"We offer excellent customer service for everyone",
        "MonthOfPassing":"",
        "YearOfPassing":"",
        "Achievements":"Friendly Team of Ashoka Enterprises will answer all your queries"
      },
    ],
    "work":[
      {
        "CompanyName":"We build a relationship of Trust",
        "specialization":"Once you buy from us, you will always buy from us because you will love it",
        "MonthOfLeaving":"",
        "YearOfLeaving":"",
        "Achievements":""
      },

    ],
    "skill":[
      {
        "CompanyName":"Elevate Your Home Comfort with Thoughtful Appliance Solutions",
        "specialization":"We offer premium products that make your home, a real home where you feel the comfort",
        "MonthOfLeaving":"",
        "YearOfLeaving":"",
        "Achievements":""
      },

    ],
    "skillsDescription":"Your skills here",
    "skills":[
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"Reactjs"
      }
    ],
    "portfolio":[
      {
        "name":"Laptops",
        "description":"",
        "imgurl":"images/portfolio/1.png"
      },
      {
        "name":"LED's",
        "description":"",
        "imgurl":"images/portfolio/2.png"
      },
      {
        "name":"Washing Machine",
        "description":"",  
        "imgurl":"images/portfolio/3.png"
      },
      {
        "name":"Microwave",
        "description":"",
        "imgurl":"images/portfolio/4.png"
      },
      {
        "name":"Refrigrator",
        "description":"",
        "imgurl":"images/portfolio/5.png"
      },
      {
        "name":"Air Conditioner",
        "description":"",
        "imgurl":"images/portfolio/6.png"
      },
      {
        "name":"Smart Phones",
        "description":"",
        "imgurl":"images/portfolio/7.png"
      },
      {
        "name":"Kitchen Stove",
        "description":"",
        "imgurl":"images/portfolio/8.png"
      },

    ],
    "testimonials":[
      {
        "description":"I bought a LED from Ashoka Enterprises and team is really helpful. Great Experience",
        "name":"Happy Customer"
      },
      {
        "description":"I always buy electronics from Ashoka Enterprises because I can reach out for any support and get quick solution",
        "name":"Happy Customer"
      },
      {
        "description":"I always upgrade my old appliances at Ashoka Enterprises, Exchange offers are really great",
        "name":"Happy Customer"
      },
    ]
  }
  
  export default resumeData